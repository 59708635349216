import React from 'react';
import Highlight from 'react-highlight'

function TilJpa(props) {
    return (
        <div className="til-container break-keep">
            <div className="text-gray-500 font-bold text-sm mb-8">
                기본적인 JPA 쿼리 튜닝 수행 (N+1, Delete쿼리 간소화)
            </div>
            <div className="relative text-center bg-sky-50 w-fit mt-2 mb-10 mx-auto px-7 pb-4 pt-5 break-keep">
                JPA는 기존에 반복적으로 작성하던 쿼리를 간편하게 자동으로 생성해주는 장점이 있습니다. <br/>
                하지만 그로 인해 기대했던 쿼리와는 다르게 생성되는 경우가 많습니다.<br/>
                흔하게 일컫는 N+1문제나 Delete쿼리전에 select를 사전에 생성하는 쿼리도 이와 같은 맥락입니다.<br/><br/>
                중요한 것은 우선적으로 어떠한 방식의 쿼리를 생성하고자 하는지 정할 수 있어야하고,<br/>
                원하는 방향으로 쿼리를 생성할 수 있게 JPA가 어떻게 동작하는지 숙지하고<br/>
                항상 발생하는 쿼리를 확인하며 원하는 방향으로 수정할 수 있어야 한다는 것을 느꼈습니다.
                <span className="absolute top-3 left-3 text-gray-400 font-bold">"</span>
                <span className="absolute top-3 right-3 text-gray-400 font-bold">"</span>
            </div>
            <div className="mb-16">
                <h2>1. N+1 현상</h2>
                <div className="mb-4">
                    <h3>상황</h3>
                    <h4>&nbsp;
                        '목표' - '사용자' 연관관계에 있다. 목표를 조회할 때, 결과적으로 사용되지 않는 사용자의 데이터가 조인되어서 하나의 쿼리가 아닌, 조회된 목표 N개 만큼의 유저를
                        조회하는 불필요한 쿼리가 발생.
                    </h4>
                </div>
                <div className="mb-4">
                    <h3>개선방향</h3>
                    <h4>&nbsp;
                        사용되지 않는 매핑된 객체 LAZY로딩 적용시켰다. 결과적으로 해당 객체는 프록시 객체로 대체되었으며, 직접적으로 프록시 객체의 필드에 접근하지 않기에 프록시 객체의
                        초기화는 발생하지 않았다. 즉, 사용되지 않는 매핑된 객체에 대한 N번의 조회 쿼리가 발생하지 않았다.
                    </h4>
                </div>
                <div className="mb-4">
                    <h3>이후 예상가능한 상황에 대한 대비</h3>
                    <h4>&nbsp;
                        위 개선 사항만으로는 추후 프록시 객체로 대체되었던 객체의 데이터에 접근하도록 비즈니스로직이 변경된다면, 또 다시 N+1현상이 발생할 수 있는 임시적으로 해결된 상태이다.
                        이런 경우에 추가적으로 발생하는 N번의 조회를 묶어서 하나의 조회 쿼리로 발생해줄 수 있게, hibernate의 기본 배치사이즈를 지정해 주었다.
                    </h4>
                    <h4>&nbsp;
                        하지만 처음부터 사용할 계획이라면 fetch Join을 이용해 애당초 조인된 데이터들을 가져와 한번의 쿼리로 사용되는 모든 객체를 영속성 컨텍스트에 가져올 수 있게 되어
                        추가적인 N번의 쿼리를 발생시키지 않을 수 있다.
                    </h4>
                </div>
            </div>
            <div className="mb-10">
                <h2>2. Delete쿼리 이전 Select쿼리 발생</h2>
                <div className="mb-4">
                    <h3>상황</h3>
                    <h4>&nbsp;
                        상속받은 SimpleJpaRepository에 구현된 delete메소드에서는 삭제하려는 객체가 존재하는 지 확인하고 영속성 컨텐스트에 없다면 가져오기 위해 Select쿼리가 발생했다.
                    </h4>
                    <h4>&nbsp;
                        이후 entityManager의 remove메소드를 통해 삭제 쿼리를 날려주고 있었는데, 이는 비즈니스 로직상 존재하는 객체임이 확인되었기에 Select쿼리를 생성하지 않아도 되는 상황이었다.
                    </h4>

                </div>
                <div className="mb-4">
                    <h3>개선방향</h3>
                    <h4>&nbsp;
                        delete 관련 쿼리메소드에 직접 네이티브 쿼리를 지정해주었다. 이를 통해 원하는 쿼리를 직접적으로 보낼 수 있었다.
                    </h4>
                </div>
            </div>

        </div>
    );
}

export default TilJpa;
