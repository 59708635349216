import React from 'react';

function Summary(props) {
    return (
        <div className="bg-gray-200 rounded-3xl shadow-sm">
            <div className="my-5 p-10 pt-20 bg-white rounded-3xl shadow-sm">
                <div className="flex items-center">
                    <img className="size-64 border-2 bg-white rounded-full " src="/image/profileImg.png"/>
                    <div className="ml-10">
                        <p className="break-keep">
                            <h2 className="text-5xl font-bold mt-2">안녕하세요! 👋</h2><br/>
                            <div className="text-[1.8rem] text-gray-900 leading-normal">
                                <p className="hover:font-bold transition-all ease-in-out">120%에 도전하며 성장하는,</p>
                                <p className="hover:font-bold transition-all ease-in-out">기술을 위한 기술이 아닌 본질에 집중하는,</p>
                                <p className="hover:font-bold transition-all ease-in-out">개발자는 개발만 잘하면 되는 것이 아니라는 것을
                                    아는,</p>
                                <p className="mt-3"><span className="hover:font-bold transition-all ease-in-out">백엔드 개발자 김현우</span>입니다.
                                </p>
                            </div>
                        </p>
                    </div>
                </div>
                <div className="mt-14">
                    <p className="text-gray-700 text-center mb-3">. . .</p>
                    <div className="flex justify-center gap-x-3">
                        <div className="p-2 px-4 rounded-full bg-orange-800 text-white font-bold" >Java</div>
                        <div className="p-2 px-4 rounded-full bg-green-800 text-white font-bold" >Spring Boot</div>
                        <div className="p-2 px-4 rounded-full bg-cyan-800 text-white font-bold" >JPA</div>
                        <div className="p-2 px-4 rounded-full bg-blue-800 text-white font-bold" >MySQL</div>
                        <div className="p-2 px-4 rounded-full bg-blue-700 text-white font-bold" >DOCKER</div>
                        <div className="p-2 px-4 rounded-full bg-orange-500 text-white font-bold" >AWS</div>
                        <div className="p-2 px-4 rounded-full bg-gray-900 text-white font-bold" >GIT</div>
                        <div className="p-2 px-4 rounded-full bg-sky-200 text-white font-bold cursor-help" >React.js</div>
                    </div>
                </div>
            </div>
            <div className="flex justify-around pb-5 gap-x-5 px-8">
                <div className="flex flex-col w-1/3 bg-white rounded-xl">
                    <div className="text-center text-white  font-bold p-2 bg-gray-900 rounded-t-xl">120% 도전하며 성장</div>
                    <div className="p-3 pr-2 my-auto">
                        <p className="mb-2">주어진 요구사항을 100% 완료를 우선하고, 이후 추가적인 20%에 도전하며 성장해오길 반복했었습니다.</p>
                        <p className="mb-2">100%에 안주하게 되는 편안함의 유혹과 200%에 도전하려는 조급함의 위험을 경험했습니다.</p>
                    </div>
                </div>
                <div className="w-1/3 bg-white rounded-xl">
                    <div className="text-center text-white  font-bold p-2 bg-gray-900 rounded-t-xl">기술을 위한 기술이 아닌 본질
                    </div>
                    <div className="p-3 pr-2 my-auto">
                        <p className="mb-2">모두가 사용한다고 좋은점만 보고 기술을 도입하던 과거를 반성합니다.<br/></p>
                        기술은 항상 변합니다. 중요한 것은 기술의 특징과 장단점을 근본적으로 이해한 뒤, 비즈니스 상황에 맞게 적용하여 사용자에게 문제없이 서비스를 제공하는 것에 있다고 생각합니다.
                    </div>
                </div>
                <div className="w-1/3 bg-white rounded-xl">
                    <div className="text-center text-white  font-bold p-2 bg-gray-900 rounded-t-xl">개발자는 개발만 잘하면 된다?
                    </div>
                    <div className="p-3 pr-2 my-auto">
                        <p className="mb-2">모든 일은 결국 사람을 거칩니다.</p>
                        <p className="mb-2">오랜 기간에 걸쳐 다양한 팀원들과 한 방향으로 무언가를 만들어내는 영상제작업을 해오며, 커뮤니케이션의 중요성을 느꼈습니다.</p>
                        <p className="mb-2">좋은 개발자임과 동시에 좋은 동료가 되고자 노력합니다.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Summary;
