import React from 'react';

function Career(props) {
    return (
        <div className="my-5 p-10 bg-white rounded-3xl shadow-sm">
            <h2>경험 & 배움</h2>
            <div className="flex px-2">
                <div className="pt-1 pb-16 m-5">
                    <div className="h-full border-2 border-dashed"/>
                </div>
                <div>
                    <div className="mb-8">
                        <div className="flex items-center relative">
                            <div className="size-3 bg-green-400 absolute -left-[28px] rounded-full"/>
                            <h3 className="font-bold">개발 직무 : 웹개발자</h3>
                        </div>
                        <div className="text-sm -mt-0.5 text-gray-700">2024.01 ~ 2024.04 (3개월)</div>
                        <h4>
                            공공기관 유지보수 직무수행
                            <li>비밀번호 규칙 등 보안성 강화 세팅 CRUD기능 구현 (Java/Spring, JSP, MyBatis)</li>
                            <li>Linux 서버세팅 (Nginx, Tomcat, DOCKER)</li>
                        </h4>
                    </div>
                    <div className="mb-8">
                        <div className="flex items-center relative">
                            <div className="size-3 bg-green-400 absolute -left-[28px] rounded-full"/>
                            <h3 className="font-bold">내일배움캠프 : 백엔드 교육</h3>
                        </div>
                        <div className="text-sm -mt-0.5 text-gray-700">2023.05 ~ 2023.09 (5개월)</div>
                        <h4>
                            Java/Spring기반으로 객체지향적 프로그래밍과 REST API 설계에 대해 학습하였고,<br/>
                            이를 기반으로 5회에 걸친 팀 프로젝트를 통해 협업하며 개발하는 방식을 익혔습니다.
                        </h4>
                    </div>
                    <div className="mb-8">
                        <div className="flex items-center relative">
                            <div className="size-3 bg-yellow-400 absolute -left-[28px] rounded-full"/>
                            <h3 className="font-bold">비개발 직무 : 영상제작업</h3>
                        </div>
                        <div className="text-sm -mt-0.5 text-gray-700">2017.08 ~ 2023.01 (5년 6개월)</div>
                        <h4>
                            <p className="mb-1.5">프로덕션, 광고대행사, IT서비스 기업 등 다양한 분야에서 직접 하며 영상을 제작해왔습니다.<br/>
                            수많은 비즈니스 요소 가운데에서 결과물에 대한 피드백을 받으며 개선해 나아가는 것에 거부감이 없습니다.<br/></p>
                            <p>또한, 다양한 환경에서 커뮤니케이션하며 직무 외적인 요소가 결과물에 좋은 영향을 끼치는 모습을 확인했습니다.<br/>
                                덕분에 직무 외적인 요소의 중요성에 대해 이해하고 있습니다.</p>
                        </h4>
                    </div>
                    <div>
                        <div className="flex items-center relative">
                            <div className="size-3 bg-cyan-800 absolute -left-[28px] rounded-full"/>
                            <h3 className="font-bold">한양대학교 Erica캠퍼스 화학공학과</h3>
                        </div>
                        <div className="text-sm -mt-0.5 text-gray-700">2011.03 ~ 2020.02 (졸업)</div>
                        <h4>광고학과 부전공</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Career;
