import React, {useState} from 'react';

function ModalPopup({children}) {

    const [isOpen, setIsOpen] = useState(false);

    const handleBackgroundClick = () => {
        setIsOpen(false);
    };

    const handleThumbClick = () => {
        setIsOpen(true);
    };

    const [Thumb, Content] = React.Children.toArray(children);

    return (
        <>
            <div onClick={handleThumbClick}>
                {Thumb}
            </div>

            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50 backdrop-blur-[2px] z-50 cursor-pointer"
                     title="클릭하면 닫힙니다" onClick={handleBackgroundClick} >
                    <div className="bg-white p-4 rounded border-[1px] border-gray-400">
                        {/*onClick={(e) => e.stopPropagation()*/}
                        {Content}
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalPopup;
