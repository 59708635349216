import React from 'react';
import {FaGithub} from "react-icons/fa6";
import {SiTistory} from "react-icons/si";

function Link(props) {
    return (
        <div className="pt-20 p-1">
            <p className="text-white text-center font-bold text-xl mb-5">Contact</p>
            <div className="w-fit mx-auto mb-3 pl-4">
                <p className="text-white">📳 010-6619-0907</p>
                <p className="text-white">✉️ wooin.dev@gmail.com</p>
            </div>
            <div className="flex justify-center gap-x-2">
                <FaGithub className="bg-white size-10 rounded-full p-1 cursor-pointer"
                          onClick={() => window.open("https://github.com/Wooin-dev", "_blank")}/>
                <div className="bg-white size-10 rounded-full cursor-pointer">
                <SiTistory className="size-6 m-auto mt-2.5"
                           onClick={() => window.open("https://thiswooin.tistory.com/", "_blank")}/>
                </div>
            </div>
            <p className="text-center text-sm text-gray-500 font-bold mt-10">이 페이지는 React.js와 tailwind로 제작되었습니다.</p>
        </div>
    );
}

export default Link;
