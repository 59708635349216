import './App.css';
import Project from "./components/Project";
import Feedback from "./components/Feedback";
import Link from "./components/Link";
import Summary from "./components/Summary";
import Career from "./components/Career";
import QnA from "./components/QnA";

function App() {
    return (
        <div className="w-[1000px] mx-auto px-3">
            <header className="flex justify-around relative bg-gray-900 my-10 rounded-full">
                <p className="p-6 text-center text-2xl font-bold">
                    <span className="text-yellow-500 mr-3">Resume{'<'}Backend{'>'}</span> <span className="text-white">kimHyeonWoo;</span>
                </p>
            </header>
            <Summary/>
            <Career />
            <Project />
            <QnA />
            <Feedback />
        </div>
    );
}

export default App;
