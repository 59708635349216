import React from 'react';

function TilRedis(props) {
    return (
        <div className="til-container">
            <div className="text-gray-500 font-bold text-sm mb-8">
                Redis 캐시 서버를 통한 인증/인가시 User 캐시 조회 적용
            </div>
            <div className="relative text-center bg-sky-50 w-fit mt-2 mb-10 mx-auto px-7 pb-4 pt-5 break-keep">
                캐시서버는 빨라서 쓰는 것이 아니다. <span className="line-through">물론 빠르다.</span><br/>
                근본적으로 사용하는 이유는 DB서버로 가는 비효율적인 쿼리를 효율적으로 분산할 수 있기 때문이다.<br/>
                Redis와 같은 인메모리DB가 이러한 캐시서버에 적합할 뿐이다.
                <p className="absolute top-3 left-3 text-gray-400 font-bold">"</p>
                <p className="absolute top-3 right-3 text-gray-400 font-bold">"</p>
            </div>
            <div className="break-keep">
                <div className="mb-6">
                    <img className="mx-auto" src="/image/project/done/til/redis/til_redis2.png" alt="redis til 이미지1"/>
                </div>
                <div className="mb-6">
                    <h3>상황</h3>
                    로그인한 회원은 나의 목표 조회, DONE 수행, 달력 조회 등 로그인 후 집중된 시간동안 반복적으로 인증/인가가 필요했다.<br/>
                    추가된 필터에서 헤더에 담겨온 JWT에 있는 email을 기준으로 User객체를 select 해오고 있었다.<br/>
                    짧은 시간동안 반복적으로 조회되고 이후에는 바로 삭제해도 치명적이지 않아보였다.<br/>
                    캐싱 서버가 다운되어도 기존DB에서는 반드시 데이터를 가져와야만 했다.<br/>
                </div>
                <div className="mb-6">
                    <h3>개선방향</h3>
                    user객체에 대한 데이터를 Redis서버에서 캐싱하도록 설정한다.<br/>
                    로그인시에 캐시 데이터를 무조건 Set, 이후 인증/인가에 의해 접속한 유저를 조회할 시에 캐시에 먼저 조회해본다.<br/>
                    반드시 데이터를 가져와야 하고, 트래픽이 많지 않으므로 Look-Aside전략을 취한다.<br/>
                </div>
                <div className="mb-6">
                    <h3>결과</h3>
                    select user 쿼리 생략으로 인한 DB 부하 감소. 캐시로 인한 조회 속도 다소 상승.
                </div>
                <div className="mb-6">
                    <h3>느낀 점</h3>
                    캐싱을 통한 빠른 조회속도도 의미가 있겠지만 비효율적인 쿼리 발생이 줄어든 의미도 크다.<br/>
                    인메모리 DB 특성상 빠른 조회는 어찌보면 당연하고, 캐싱을 어떤 데이터에 어떤 전략으로 적용할 지, 캐시 서버가 다운됐을 경우 등 더 깊게 고민해볼 부분이 많다.
                </div>
            </div>
        </div>
    );
}

export default TilRedis;
