import React, {useState} from 'react';
import Link from "./Link";
import axios from "axios";

function Feedback(props) {

    const BASE_URI = process.env.REACT_APP_API_BASE_URI;
    const [directComment, setDirectComment] = useState();

    const sendFeedback = (code, comment) => {

        // eslint-disable-next-line no-restricted-globals
        if(!confirm("의견을 보내시겠어요?")) {return;}

        axios.post(`${BASE_URI}/api/feedback`,
            {
                feedbackCode: code,
                feedbackComment: comment
            }).then(res => {
            console.log(res);
            alert("좋은 의견 감사합니다! 😊")
        }).catch(e => {
            console.log(e);
            alert("오류가 발생했어요. 빨리 수정할게요 😭");
        })
    }

    const activeEnter = (e) => {
        if(e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            sendFeedback("DIRECT",directComment);
        }
    }

    const feedbackOptions = [
        {
            comment: "좋은데요! 👍",
            code: "GOOD"
        },
        {
            comment: "내용이 너무 많아요",
            code: "TMI"
        },
        {
            comment: "읽기 힘들어요",
            code: "BAD_READABLE"
        },
        {
            comment: "직무 능력을 확인하기 어려워요",
            code: "CHECK_PROFESSIONAL"
        },
        {
            comment: "깊은 내용을 확인할 수 없어요",
            code: "NOT_DEEP_INFORMATION"
        },
        {
            comment: "우리 회사와는 맞지 않은 것 같아요",
            code: "NOT_FIT"
        },
    ]
    return (
        <div className="mt-20 pb-2 bg-gray-800 rounded-t-3xl shadow-sm">
            <div className="feedback-container rounded-3xl">
                <div>
                    <h2 className="text-center text-white pt-10">저의 이력서는 어떠셨나요?</h2>
                    <div className="flex flex-wrap justify-center px-5 gap-x-5 gap-y-2">
                        {feedbackOptions.map(option => (
                            <div className="px-5 py-2 bg-white rounded-full shadow-sm text-gray-900 font-bold cursor-pointer
                                hover:scale-110 transition-transform"
                                 onClick={() => sendFeedback(option.code, option.comment)}>
                                {option.comment}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pb-10">
                    <h2 className="text-center text-white pt-10 mb-0">혹시 해주고 싶은 조언이 있으시다면..?</h2>
                    <h5 className="text-center text-white font-bold py-0 mt-0 mb-4">의견은 익명으로 전송됩니다</h5>
                    <div
                        className="relative flex items-center justify-end py-3 px-6 mx-16 rounded-xl bg-white text-center">
                        <input className="w-full border-b-[1px] mr-28" value={directComment} onKeyDown={e=> activeEnter(e)} onChange={e=> setDirectComment(e.target.value)}/>
                        <div
                            className="absolute bg-gray-700 text-white font-bold rounded-full px-3 py-1 cursor-pointer"
                            onClick={() => sendFeedback("DIRECT",directComment)}
                        >전달하기
                        </div>
                    </div>
                </div>
            </div>

            <Link/>
        </div>
    );
}

export default Feedback;
