import React, {useEffect, useRef, useState} from 'react';
import {Popover, PopoverButton, PopoverPanel, Transition} from '@headlessui/react'
import {FaCaretDown} from "react-icons/fa6";

function QnA(props) {

    const cards = [
        {
            question: "본인을 간략하게 표현해보세요.",
            answer: " 학창시절엔 시키는 공부를 그저 열심히 했고, " +
                "대학교에 와서는 제가 좋아하는 일을 찾다가 영상을 만들기 시작했으며, " +
                "이제는 제가 잘 할 수 있는 것을 찾던 끝에 개발자의 커리어를 시작하고자 합니다." +
                "\n\n 팀적으로는 협업의 시너지, 개인적으로는 성장에 대한 성취감을 가장 중요하게 생각합니다. " +
                "제 역량에 200%에 도전하다가 제 자신에게 지쳐본 적도 있고 100%에 안주하며 권태의 유혹에 빠졌던 적도 있습니다. " +
                "이러한 소중한 경험들 덕분에 이제는 120%에 항상 도전하며 오래동안 꾸준히 성장하고자 하는 마음가짐을 갖고자 노력합니다."
        },
        {
            question: "영상을 그만두고 개발자가 되려고 한 이유가 뭔가요?",
            answer: " 어느 순간부터 공부한만큼 성취를 이뤄낼 수 없었습니다. " +
                "커리어 초반에는 새로운 촬영,편집기술을 배워서 이전보다 더 좋은 퀄리티로 결과물을 만들어낼 때 큰 성취감을 느끼며 이를 큰 동기부여로 삼아 약 5년간 꾸준히 성장해왔었습니다. " +
                "하지만 영상제작에서는 일정 수준을 넘어가자 영업적인 부분과 감각적인 역량에 많이 의존할 수 밖에 없었고, 한 분야에 몰두하여 점진적으로 성장하는 저의 성향이 더 이상 장점으로써 작용하지 못 하게 됐습니다." +
                "\n\n 개발은 무언가를 기획하고 만들어내고 끊임 없이 개선해나가는 점이 영상제작과 닮았습니다. " +
                "그리고 오랜 시간 쌓여온 개발에서의 클래식같은 기술들과 빠르게 등장하는 새로운 기술들을 보면서, 끊임없이 공부하고 시도해보는 저의 성향이 장점으로 발휘될 수 있다고 생각했고, 개발자로의 직무전환을 결정했습니다."
        },
        // {
        //     question: "본인이 다른 지원자들보다 뛰어난 점은 무엇이라고 생각하나요?",
        //     answer: ""
        // },
        {
            question: "본인보다 어린 선배와 일하는 것이 어렵진 않은가요?",
            answer: "이전의 두가지 경험 덕분에 큰 어려움 없이 일할 수 있습니다." +
                "\n\n첫번째 경험으로는 두살 동생의 지시 아래 일했던 경험입니다. 저는 조감독이었고 감독은 저보다 훨씬 어렸던 적이 있습니다." +
                " 일을 처음 시작할 때 제가 가졌던 마음가짐은 '나이 많다고 어설프게 일하지 말자'였습니다. 약 한 달 간의 제작 기간동안 서로 존중하며 업무를 진행할 수 있었습니다. 덕분에 감독으로부터 많은 걸 배웠고, 저 또한 제 역할을 다 할 수 있었습니다." +
                "\n\n두번째 경험으로는 반대의 입장에서 제가 10살 이상의 선배님들을 지시한 경험입니다." +
                " 제가 감독을 맡았을 때 10살 이상 많으신 촬영감독, 조명감독님과 같이 촬영을 진행했었습니다." +
                " 그 때 당시 저에게 나이가 많다고해서 무시하는 듯한 뉘앙스를 전혀 느끼지 못 했고, 서로 좋은 결과물을 위해 자유롭게 의견을 나누며 촬영을 잘 마무리 할 수 있었습니다." +
                "\n\n나이를 떠나서 존중했을 때 성장했던 경험과 존중받았을 때의 감사함을 알고, 이를 토대로 나이가 어린 상급자와 일을 하게 될 경우에 더더욱 유의하여 존중을 잃지 않으며 일할 수 있습니다."
        },
        {
            question: "'개발을 잘 한다'라는 말이 어떤 의미라고 생각하세요?",
            answer: " 불특정 다수의 사용자가 다양한 환경에서 요청한 데이터를 안정적으로 응답해주는 서비스를 오랜 기간 효과적으로 개선하고 유지하는 것이 개발자의 궁극적인 직무라고 생각합니다. 이상적인 말이지만 이 직무를 현실의 상황에 맞게 최대한 이상에 가깝게 수행하는 것이 개발을 잘 한다라고 생각합니다." +
                "\n\n 그러기 위해서는 우선, 비즈니스 환경과 추후의 상황을 다양한 경험을 통해 예측하여 대비하는 계획을 세울 수 있어야하고, " +
                "그것에 맞게 기술의 특장점을 컴퓨터 공학에 기반하여 근본적인 이해를 통해 얻을 수 있는 장점과 감수해야하는 단점을 기반으로 한 기술적 의사선택이 이루어져야합니다."
        },
    ]
    const [activeIndex, setActiveIndex] = useState(null);
    const answerRefs = useRef([]);
    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(() => {
        answerRefs.current.forEach((ref, index) => {
            if (ref) {
                ref.style.maxHeight = activeIndex === index ? `${ref.scrollHeight + 15}px` : '0';
            }
        });
    }, [activeIndex]);

    return (
        <div className="my-5 p-10 bg-white rounded-3xl shadow-sm">
            <h2>미리 답변드려봅니다 😊</h2>
            <div className="faq-list-box">
                {cards.map((card, index) => (
                    <div key={index} className="faq-item " onClick={() => toggleAnswer(index)}>
                        <div className="flex justify-between">
                            <p className="font-bold">Q. {card.question}</p>
                            <FaCaretDown className={`fill-gray-700 ${activeIndex === index && 'rotate-180'} `}/>
                        </div>
                        <div
                            ref={(el) => (answerRefs.current[index] = el)}
                            className={`answer-box ${activeIndex === index ? 'active' : ''} mx-5 whitespace-pre-wrap`}
                        >
                            {card.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
        ;
}

export default QnA;
