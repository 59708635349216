import React from 'react';
import ModalPopup from "./ModalPopup";
import TILJPA from "./project/TIL-JPA";
import {HiMiniMagnifyingGlassPlus} from "react-icons/hi2";
import TILRedis from "./project/TIL-Redis";
import TILSimpleGoal from "./project/TIL-SimpleGoal";
import Plan from "./project/Plan";
import {FaGithub} from "react-icons/fa";

function Project(props) {
    return (
        <div className="my-5 py-10 bg-white rounded-3xl shadow-sm">
            <h2 className="px-10">프로젝트</h2>
            <div className="m-5 border-slate-800 rounded-3xl shadow-lg">
                <div className="py-1 text-white font-bold text-center bg-slate-800 rounded-t-3xl">
                    <h3 className="">D'ONE</h3>
                    <span>개인 프로젝트 (2024.04 ~ 진행중)</span>
                </div>
                <div className="p-10">
                    <div className="flex justify-center gap-x-2 mb-8">
                        <img className="h-[450px] border-2" src="/image/project/done/capture1.png" alt="done 캡쳐이미지1"/>
                        <img className="h-[450px] border-2" src="/image/project/done/capture2.png" alt="done 캡쳐이미지2"/>
                        <img className="h-[450px] border-2" src="/image/project/done/calendar_1.png" alt="done 캡쳐이미지3"/>
                    </div>
                    <div className="flex">
                        <div className="flex flex-col gap-y-3 bg-white rounded-3xl">
                            <div>
                                <p className="font-bold">프로젝트 소개</p>
                                <p>1일동안 100개하는 것보다 100일동안 매일 1개하는 것이 더 어렵습니다.</p>
                                <p>더 쉬운 목표를 제안하고, 이를 달성시 원래 목표를 달성할 수 있는 목표 달성 서비스입니다.</p>
                            </div>
                            <div>
                                <p className="font-bold">주요개발사항</p>
                                <ModalPopup>
                                    <div className="tilBtn">
                                        <p>- Redis 캐시 서버를 통한 인증/인가시 User 캐시 조회 적용</p>
                                        <HiMiniMagnifyingGlassPlus className="size-6 pb-0.5 p-1 fill-gray-700"/>
                                    </div>
                                    <TILRedis/>
                                </ModalPopup>
                                <ModalPopup>
                                    <div className="tilBtn">
                                        <p>- 기본적인 JPA 쿼리 튜닝 수행 (N+1, Delete쿼리 간소화)</p>
                                        <HiMiniMagnifyingGlassPlus className="size-6 pb-0.5 p-1 fill-gray-700"/>
                                    </div>
                                    <TILJPA/>
                                </ModalPopup>
                                <ModalPopup>
                                    <div className="tilBtn">
                                        <p>- 목표생성시 좀 더 낮은 수치의 목표 자동 생성을 재귀함수를 활용하여 객체지향적으로 구현</p>
                                        <HiMiniMagnifyingGlassPlus className="size-6 pb-0.5 p-1 fill-gray-700"/>
                                    </div>
                                    <TILSimpleGoal />
                                </ModalPopup>
                            </div>
                            <div>
                                <p className="font-bold">기타 구현요소</p>
                                <p>- Nginx를 이용한 리버스프록시 서버 구성 & Docker-composer를 통한 자동배포 구현</p>
                                <p>- 카카오 소셜로그인 외부 API 연동</p>
                                <p>- 목표, DONE(쉬운달성), SUPER-DONE 등 데이터 모델링 수행</p>
                            </div>
                            <div>
                                <p className="font-bold">Link</p>
                                <div className="flex gap-x-2 mt-1">
                                    <div className="linkBtn"
                                         onClick={() => window.open("https://done.n-e.kr/", '_blank')}>
                                        웹 링크
                                    </div>
                                    <div className="linkBtn flex items-center"
                                         onClick={() => window.open("https://github.com/Wooin-dev/dailyone_server", '_blank')}>
                                        백엔드
                                        <FaGithub className="fill-gray-700 size-5 ml-1"/>
                                    </div>
                                    <div className="linkBtn flex items-center"
                                         onClick={() => window.open("https://github.com/Wooin-dev/dailyone_web", '_blank')}>
                                        프론트엔드
                                        <FaGithub className="fill-gray-700 size-5 ml-1"/>
                                    </div>
                                    <div className="linkBtn"
                                         onClick={() => window.open("https://github.com/users/Wooin-dev/projects/1/views/1", '_blank')}>
                                        Github-Project
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-fit border-l-[1px] border-gray-300 ml-5 my-2 p-5">
                            <ModalPopup>
                                <button className="projectModalBtn">Architecture</button>
                                <div className="">
                                    <div className="text-gray-500 font-bold text-sm mb-3">
                                        Architecture
                                    </div>
                                    <img className="p-5 border-2" src="/image/project/done/architecture.png"/>
                                </div>
                            </ModalPopup>
                            <ModalPopup>
                                <button className="projectModalBtn">ERD</button>
                                <div className="">
                                    <div className="text-gray-500 font-bold text-sm mb-3">
                                        ERD
                                    </div>
                                    <img className="p-5 border-2" src="/image/project/done/erd.png"/>
                                </div>
                            </ModalPopup>
                            <ModalPopup>
                                <button className="projectModalBtn">향후 계획</button>
                                <Plan />
                            </ModalPopup>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="h-96 m-5 border-slate-800 rounded-3xl shadow-lg">*/}
            {/*    <div className="py-1 text-white font-bold text-center bg-cyan-800 rounded-t-3xl">*/}
            {/*        <h3 className="">La-Festa</h3>*/}
            {/*        <span>4인 팀 프로젝트 (2023.08 ~ 2023.09)</span>*/}
            {/*    </div>*/}
            {/*    <div className="p-2 bg-white rounded-3xl"></div>*/}
            {/*</div>*/}
        </div>
    );
}

export default Project;
