import React from 'react';

function Plan(props) {
    return (
        <div className="til-container">
            <div className="text-gray-500 font-bold mb-8">
                향후 계획
            </div>
            <div className="mb-16">
                <h2>1. D'ONE 추가 기능</h2>
                <div className="mb-4">
                    <h3>DONE마다 기록 남기기</h3>
                    <p>현재 DONE, SUPER DONE 버튼을 눌러 기록을 남기는 것이 다인데, 여기에 메모나 첨부사진같은 것을 기록할 수 있게 확장.</p>
                </div>
                <div className="mb-4">
                    <h3>DONE 미수행시 동기부여 알림 발송</h3>
                    <p>Spring Batch를 활용하여 트래픽이 적은 시간대에 알림을 보낼 리스트를 추려내고 오전 시간대에 일괄 전송. </p>
                </div>
                <div className="mb-4">
                    <h3>목표 실패시 패널티 다양화 (알림톡 발송, 선물하기 등)</h3>
                    <p>목표 실패에 대한 다양한 기준을 생성하고, 실패했을 제3자에게 특정 메시지나, 선물을 보내도록 예약하는 기능. </p>
                </div>
            </div>
            <div className="mb-10">
                <h2>2. 피드를 통한 SNS기능 강화</h2>
                <div className="mb-4">
                    <h3>다른 사용자들의 다양한 액션을 확인할 수 있는 피드</h3>
                    <p>
                        누군가 며칠 연속 목표를 수행한다거나, 실패, 성공 등 목표에 대한 다양한 일들을 확인하고 댓글, 좋아요와 같은 반응을 할 수 있는 피드.
                    </p>
                </div>
                <div className="mb-4">
                    <h3>동기부여 코멘트만 모아서 볼 수 있는 탭</h3>
                    <p>
                        목표를 생성할 때 설정한 동기부여 멘트를 공개하거나, 동기부여 멘트만 따로 작성한 리스트를 피드 형식으로 확인 가능한 탭.
                        여기에 댓글을 달거나 좋아요와 같은 반응을 할 수 있다.
                    </p>
                </div>
            </div>
            <div className="mb-10">
                <div className="mb-4">
                    <h2>+배포, 서버구성</h2>
                    <h3>어플출시</h3>
                    <p>
                        최소한 웹뷰의 형태로 어플로 출시하여 실제 서비스를 진행해보고자 합니다.
                    </p>
                    <h3 className="mt-4">홈서버구성</h3>
                    <p>
                       AWS 프리티어의 스펙이 힘들게 된다면 직접 서버를 구성해보고자 합니다.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Plan;
