import React from 'react';
import {FaExternalLinkAlt, FaGithub} from "react-icons/fa";

function TilSimpleGoal(props) {
    return (
        <div className="til-container">
            <div className="text-gray-500 font-bold text-sm mb-8">
                목표생성시 좀 더 낮은 수치의 목표 자동 생성을 재귀함수를 활용하여 객체지향적으로 구현
            </div>
            <div className="relative text-center bg-sky-50 w-fit mt-2 mb-10 mx-auto px-10 pb-4 pt-5 break-keep">
                처음 세운 목표를 쉽게 만들어주는 것이 해당 서비스의 핵심 기능입니다.<br/>
                당장에는 숫자를 찾아 하향조정하는 방식으로만 구현했습니다.<br/><br/>
                하지만, 이후에 목표 재설정하는 방식이 다양해질 예정이었습니다.<br/>
                그렇기에 단일책임원칙과 하나의 메소드가 하나의 기능을 담당하게 하여<br/>
                유지보수와 확장성을 고려하여 개발하는데 집중했습니다.
                <p className="absolute top-3 left-3 text-gray-400 font-bold">"</p>
                <p className="absolute top-3 right-3 text-gray-400 font-bold">"</p>
            </div>
            <div className="mb-10">
                <img className="mx-auto border-[1px] py-3 mb-1"
                     src="/image/project/done/til/simplegoal/til_simplegoal1.png" alt="simpleGoal til 이미지1"/>
                <p className="text-center text-gray-400 text-sm font-bold">목표 생성하는 화면 일부</p>
            </div>
            <div className="mb-6">
                <div className="flex items-center">
                    <h2>개발방향</h2>
                    <div className="pl-6 linkBtn flex items-center h-fit ml-5 mt-1 "
                         onClick={(e) => {
                             e.stopPropagation();
                             window.open("https://github.com/Wooin-dev/dailyone_server/blob/main/src/main/java/com/wooin/dailyone/util/SimpleGoalGenerator.java", '_blank')
                         }}>
                        해당코드
                        <FaExternalLinkAlt className="fill-gray-400 p-0.5 ml-1"/>
                    </div>
                </div>
                <div className="mb-4">
                    <h3>단일 책임 원칙</h3>
                    <h4>&nbsp;
                        목표를 단순하게 만들어주는 SimpleGoalGenerator 클래스를 별도로 만들었다.
                        이를 통해 기존의 목표로 쉬운 목표를 생성하는 기능을 SimpleGoalGenerator에서 책임을 지도록 했다.
                    </h4>
                </div>
                <div className="mb-4">
                    <h3>하나의 메소드는 하나의 기능만</h3>
                    <h4>&nbsp;
                    목표를 간단하게 만드는 메소드 하나가 기존의 목표를 입력받아 간단해진 목표를 리턴하는 모든 코드를 모아두지 않았다.
                    현재는 숫자를 찾아서 낮게 변경하는 생성방식을 갖고 있지만 이후 특정 조건에서는 다른 생성방식이 적용되도록 추가될 여지가 많다.
                    또, 숫자를 낮추는 기준이 변경될 수도 있다.<br/><br/>
                    &nbsp;결과적으로, 이후 유지보수와 가독성을 위하여 메소드 하나가 하나의 기능을 갖도록 구성했다.
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default TilSimpleGoal;
